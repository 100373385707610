<template>
  <div class="course-block" @click="goCourseDetail(courseDetail)">
    <div
      class="category-tag"
      v-if="ifMylist && courseDetail.pCategoryId"
      :style="
        'background-color:' + getbackgroundColor(courseDetail.pCategoryId)
      "
    >
      {{ courseDetail.categoryName }}
    </div>
    <div
      class="course-img"
      :style="'background-image: ' + 'url(' + courseDetail.courseCover + ')'"
      v-if="courseDetail.courseCover"
    >
      <div class="course-no-auth" v-if="!courseDetail.allowed && !ifMylist">
        <div class="no-auth-info">
          <img :src="noAuthImg" alt="" />
          <p>{{ $t("el.courseList.noPermission") }}</p>
        </div>
      </div>
    </div>
    <div
      class="course-img"
      :style="'background-image: ' + 'url(' + defaultImage + ')'"
      v-else
    >
      <div class="course-no-auth" v-if="!courseDetail.allowed && !ifMylist">
        <div class="no-auth-info">
          <img :src="noAuthImg" alt="" />
          <p>{{ $t("el.courseList.noPermission") }}</p>
        </div>
      </div>
    </div>
    <div class="course-text">
      <div class="flex" style="align-items: baseline">
        <!-- {{ courseDetail.courseName }} -->
        <tooltip-over
          :content="courseDetail.courseName"
          class="course-name"
          :style="courseDetail.isTry ? 'max-width:96px' : ''"
          :singleAble="false"
        ></tooltip-over>
        <span v-if="courseDetail.isTry" class="details-course-try">{{
          $t("el.common.onTrial")
        }}</span>
      </div>

      <div class="course-info">
        <div class="course-info-text">
          <div class="course-info-title">
            {{ $t("el.courseList.subject") }}：
          </div>
          <tooltip-over
            :content="courseDetail.subjectName"
            class="course-info-content"
            :singleAble="true"
          ></tooltip-over>
        </div>
        <div class="course-info-text">
          <div class="course-info-title">{{ $t("el.courseList.grade") }}：</div>
          <tooltip-over
            :content="courseDetail.gradeName"
            class="course-info-content"
            :singleAble="true"
          ></tooltip-over>
        </div>
        <div class="course-info-text">
          <div class="course-info-title">
            {{ $t("el.courseList.semester") }}：
          </div>
          <tooltip-over
            :content="courseDetail.termName"
            class="course-info-content"
            :singleAble="true"
          ></tooltip-over>
        </div>
        <div class="course-info-text">
          <div class="course-info-title">{{ $t("el.common.source") }}：</div>
          <tooltip-over
            :content="courseDetail.originTenantName"
            class="course-info-content"
            :singleAble="true"
          ></tooltip-over>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
export default {
  components: {
    tooltipOver,
  },
  data() {
    return {
      defaultImage: require("@/assets/images/default-image.png"), // 默认封面
      noAuthImg: require("@/assets/images/icon/no-auth.png"),
      colorList: [
        "#7487d5",
        "#e9a0cb",
        "#9a96cc",
        "#7461dc",
        "#80b8c8",
        "#84cdbb",
        "#bac578",
        "#8092e0",
        "#eba484",
      ],
    };
  },
  props: {
    courseDetail: {
      type: Object,
      default: () => {},
    },
    ifMylist: {
      type: Boolean,
      default: true,
    },
    fromPage: {
      type: String,
      default: "courseList",
    },
  },
  methods: {
    getbackgroundColor(id) {
      let sort = id % this.colorList.length;
      return this.colorList[sort];
    },
    goCourseDetail({ courseId, originTenantId, originTenantType }) {
      if (this.fromPage === "courseList") {
        localStorage.setItem("courseListId", this.$route.query.id);
      }
      localStorage.setItem("detailsFromPage", this.fromPage);
      this.$router.push({
        name: "CourseDetails",
        query: {
          id: courseId,
          originTenantId: originTenantId,
          originTenantType: originTenantType,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@mw: 1.174px;
@media screen and (min-width: 1620px) {
  .course-block {
    cursor: pointer;
    display: flex;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 11px #f3f3f3;
    padding: 20px 16px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    .course-img {
      width: 120px;
      height: 120px;
      border-radius: 6px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .course-text {
      width: calc(50% - 12 * @mw);
      height: 155px;
      margin-left: 12 * @mw;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .course-name {
        font-size: 14 * @mw;
        font-weight: bold;
        color: #131313;
        word-break: break-all;
        line-height: 24 * @mw;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .details-course-try {
        padding: 3px 12px;
        height: fit-content;
        background: rgba(0, 118, 244, 0.1);
        border-radius: 12px;
        font-size: 12px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #0076f4;
        margin-left: 12px;
        white-space: nowrap;
      }
      .course-info {
        width: 100%;
        .course-info-text {
          display: flex;
          white-space: nowrap;
          font-size: 12 * @mw;
          font-weight: 400;
          color: #666666;
          margin-top: 8 * @mw;
          width: 100%;
          // .course-info-title {
          //   width: 25%;
          // }
          .course-info-content {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        // p {
        //   font-size: 14px;
        //   font-weight: 400;
        //   color: #666666;
        //   line-height: 24px;
        // }
      }
    }
  }
  .course-no-auth {
    width: 100%;
    padding-bottom: 75%;
    position: relative;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: saturate(150%) blur(4px);
    .no-auth-info {
      width: 100 * @mw;
      height: 50 * @mw;
      position: absolute;
      top: calc(50% - 25 * @mw);
      left: calc(50% - 50 * @mw);
      text-align: center;
      img {
        width: 22 * @mw;
      }
      p {
        color: #fff;
        font-size: 12 * @mw;
      }
    }
  }
  .category-tag {
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px;
    border-radius: 4px 0px 4px 0px;
    font-size: 12 * @mw;
    color: #fff;
  }
}
@media screen and (max-width: 1619px) {
  .course-block {
    cursor: pointer;
    display: flex;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 11px #f3f3f3;
    padding: 20px 16px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    .course-img {
      width: 120px;
      height: 120px;
      border-radius: 6px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .course-text {
      width: calc(50% - 12px);
      height: 135px;
      margin-left: 12px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .course-name {
        font-size: 14px;
        font-weight: bold;
        color: #131313;
        line-height: 24px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .details-course-try {
        padding: 3px 12px;
        height: fit-content;
        background: rgba(0, 118, 244, 0.1);
        border-radius: 12px;
        font-size: 12px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #0076f4;
        margin-left: 12px;
        white-space: nowrap;
      }
      .course-info {
        width: 100%;
        .course-info-text {
          display: flex;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          margin-top: 8px;
          width: 100%;
          // .course-info-title {
          //   width: 25%;
          // }
          .course-info-content {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          // .course-info-title-en {
          //   width: 42%;
          // }
          // .course-info-content-en {
          //   width: 58%;
          // }
        }
        // p {
        //   font-size: 14px;
        //   font-weight: 400;
        //   color: #666666;
        //   line-height: 24px;
        // }
      }
    }
  }
  .course-no-auth {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: saturate(150%) blur(4px);
    .no-auth-info {
      position: absolute;
      top: calc(50% - 25px);
      left: calc(50% - 50px);
      text-align: center;
      width: 100px;
      img {
        width: 22px;
      }
      p {
        color: #fff;
        font-size: 12px;
      }
    }
  }
  .category-tag {
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px;
    border-radius: 4px 0px 4px 0px;
    font-size: 12px;
    color: #fff;
  }
}
</style>
