import axios from "@/plugins/axios.js";
/**
 * 新我的课程列表
 * @return {Promise}
 */
export function getFrontCoursePage(data) {
  return axios.get("/courseManage/courseList", {
    params: data,
  });
}

export function getcategoryDetail(data) {
  return axios.get("/courseManage/categoryDetail/" + data);
}

// 获取课程详情
export function getCourseDetail(data) {
  return axios.get(`/courseManage/detail`, {
    params: data,
  });
}
export function checkAvailability(params) {
  return axios.get("/prepareLessons/checkAvailability", {
    baseURL: "/aiykt-gateway/data-point",
    params,
  });
}

// 获取课程和班级列表
export function getCourseClassList(data) {
  return axios.get("courseManage/teach/list", {
    params: data,
  });
}
